@font-face {
  font-family: "Volte";
  src: url("../assets/fonts/Volte-Regular.woff2") format("woff2"),
    url("../assets/fonts/Volte-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Volte2";
  src: url("../assets/fonts/Volte-Regular2.woff2") format("woff2"),
    url("../assets/fonts/Volte-Regular2.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Volte4";
  src: url("../assets/fonts/Volte-Regular4.woff2") format("woff2"),
    url("../assets/fonts/Volte-Regular4.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Volte3";
  src: url("../assets/fonts/Volte-Regular3.woff2") format("woff2"),
    url("../assets/fonts/Volte-Regular3.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "VolteBold";
  src: url("../assets/fonts/Volte-Bold.woff2") format("woff2"),
    url("../assets/fonts/Volte-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
